<template>
  <div>
    <el-table
      ref="vmTable"
      v-loading="loading"
      :data="tableData">
      <el-table-column prop="fileUrl" label="文件">
        <template slot-scope="scope">
          <span v-for="item in scope.row.files" :key="item.id">
            <img
              v-if="item.fileType === 1"
              style="width: 80px;height: 80px"
              alt=""
              :src="item.path"
              @click.self="showBigImage($event)"/>
            <span v-else>视频</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="suggestion" width="" label="意见" align="center">
      </el-table-column>
      <el-table-column prop="mobile" label="电话" align="center">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
      </el-table-column>
    </el-table>
    <big-img
      :visible="photoVisible"
      :url="bigImgUrl"
      @closeClick="photoVisible = false"></big-img>
  </div>
</template>
<script>

  import BigImg from "@/components/BigImg";

  export default {
    components: {BigImg},
    data() {
      return {
        photoVisible: false,
        bigImgUrl: "",
        loading: false,
        tableData: [],
        search: {
          filter: "",
        },
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList() {
        this.$http.get("feedbacks/").then(res => {
          console.log(res);
          this.tableData = res.data.records;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      showBigImage(e) {
        //点击图片函数，点击后，把photoVisible设置成true
        if (e !== "") {
          this.photoVisible = true;
          this.bigImgUrl = e.currentTarget.src;
        }
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$http.delete("feedbacks/" + row.id).then(res => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
